<template>
  <v-card class="pa-0">
    <v-card-title class="justify-center py-5">
      <span v-if="type !== 'errorInf'" class="text-h6 font-weight-medium">¡Atención!</span>
      <template v-else>
        <v-col cols="12" class="pa-0 text-right">
          <v-btn @click="$emit('close')" text :ripple="false">
            <v-icon size="25" color="placeholder">mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center pb-0">
          <span class="subtitle-1 font-weight-bold d-block" style="line-height: 20px;">Lorem ipsum dolor sit amet, <br> consectetur adipiscing elit</span>
        </v-col>
      </template>
    </v-card-title>
    <v-divider v-if="type !== 'errorInf'" />
    <v-col :class="`${type !== 'errorInf' ? 'text-center mx-auto' : 'pt-0' }, pa-8 body-1`">
      <span v-if="type !== 'errorInf'">Esta acción te devolvera a la aplicacion de Axteroid</span>
      <template v-else>
        <span class="d-block mt-n5" style="line-height: 18px;">
          <span class="font-weight-bold">Problema:</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus nec cursus lectus. Nunc sem eros, sollicitudin non dignissim at, pretium sed est.
        </span>
        <span class="d-block mt-5" style="line-height: 18px;">
          <span class="font-weight-bold">Solución:</span> Quisque sed rhoncus urna. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus in facilisis ligula. Praesent nisi tellus, euismod eget congue vestibulum, sagittis eu lorem. Maecenas fermentum massa in ipsum lobortis auctor. Maecenas arcu sapien, elementum id iaculis sit amet, pulvinar in mauris. Suspendisse vestibulum dolor sed arcu cursus elementum.
        </span>
      </template>
    </v-col>
    <v-card-actions class="justify-end px-8 pb-6 pt-0">
      <v-btn class="px-4" :color="type !== 'errorInf' ? 'gray' : 'primary'" :outlined="type !== 'errorInf'" @click="$emit('close')">{{ type === 'errorInf' ?  'Aceptar' : 'Mantenerme en el sitio' }}</v-btn>
      <v-btn v-if="type !== 'errorInf'" class="px-4" color="primary" @click="$emit('redirect')">Continuar a la app</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    items: {
      type: Object,
      default: () => { }
    }
  }
}
</script>