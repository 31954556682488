<template>
  <v-row class="fill-height">
    <v-col>
      <v-card>
        <v-card-title class="py-2">
          <v-toolbar flat height="55">
            <v-toolbar-title class="subtitle-1">
              Cuadraturas
              <template v-if="$refs.calendar"><v-icon class="mx-2 mt-n1" color="fontBody">mdi-chevron-right</v-icon>{{ $refs.calendar.title }}</template>
            </v-toolbar-title>
            <v-btn class="ml-4 mr-2" outlined color="grey darken-2" @click="prev"><v-icon small>mdi-chevron-left</v-icon></v-btn>
            <v-btn outlined color="grey darken-2" @click="next"><v-icon small>mdi-chevron-right</v-icon></v-btn>

            <v-spacer />

            <v-btn class="mr-2" outlined color="grey darken-2" @click="setToday">Ir a hoy</v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on"><span>{{ typeToLabel[type] }}</span><v-icon right>mdi-menu-down</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Vista diaria</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Vista semanal</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Vista mensual</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer />

            <v-select v-model="organization" :items="organizations" item-text="name" item-value="tax_id" single-line hide-details dense height="30" outlined style="max-width: 310px;" />
          </v-toolbar>
        </v-card-title>

        <v-divider />

        <v-card-text class="fill-height" style="height: calc(100vh - 200px); overflow-y: auto;">
          <v-calendar ref="calendar" v-model="focus" color="primary" :events="events" :event-color="getEventColor" :event-text-color="getEventTextColor" :type="type" @click:event="showEvent" @click:more="viewDay" @click:date="viewDay" />
        </v-card-text>
      </v-card>

      <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
        <v-card width="360" outlined>
          <v-toolbar flat height="48">
            <v-toolbar-title>
              <span class="mx-3 body-1">Cuadratura PoS</span>
              <template v-if="selectedEvent.name === 'Exitoso'">
                <v-chip color="green lighten-4" small text-color="green darken-4">Exitoso<v-icon right>mdi-check-circle</v-icon></v-chip>
              </template>
              <template v-else-if="selectedEvent.name === 'En validación'">
                <v-chip color="amber lighten-4" small text-color="amber darken-4">En validación<v-icon right>mdi-clock-time-eight</v-icon></v-chip>
              </template>
              <template v-else-if="selectedEvent.name === 'Procesando'">
                <v-chip color="blue lighten-4" small text-color="blue darken-4">Procesando<v-icon right>mdi-cog-transfer</v-icon></v-chip>
              </template>
              <template v-else-if="selectedEvent.name === 'Con incidencias'">
                <v-chip color="red lighten-4" small text-color="red darken-4">Con incidencias<v-icon right>mdi-alert-circle</v-icon></v-chip>
              </template>
            </v-toolbar-title>
          </v-toolbar>

          <v-divider />

          <v-card-text>
            <v-row>
              <v-col class="text-center">
                <span class="d-block mb-3 body-2 text--secondary">Origen</span>
                <span class="d-block"><v-icon class="mt-n1" color="primary" left size="20">mdi-printer-pos</v-icon>PoS</span>
              </v-col>

              <v-divider vertical />

              <v-col class="text-center">
                <span class="d-block mb-3 body-2 text--secondary">Documentos</span>
                <span class="d-block">{{346862 | number}}</span>
              </v-col>

              <template v-if="selectedEvent.name === 'Con incidencias'">
                <v-divider vertical />

                <v-col class="text-center">
                  <span class="d-block mb-3 body-2 text--secondary">Incidencias</span>
                  <span class="d-block">{{1238 | number}}</span>
                </v-col>
              </template>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn :to="{ name: 'ComplianceQuadratureDetail', params: { id: 'foo' } }" color="primary" text>Ver cuadratura<v-icon right>mdi-arrow-right</v-icon></v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import VModal from '@/modules/compliance/components/VModal'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    VModal,
    ChartDoughnut
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    focus: '',
    type: 'week',
    typeToLabel: {
      day: 'Vista diaria',
      month: 'Vista mensual',
      week: 'Vista semanal'
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [
      // 2023-11-26
      { name: 'Exitoso', start: '2023-11-26 00:00:00', end: '2023-11-26 00:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 00:30:00', end: '2023-11-26 01:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 01:00:00', end: '2023-11-26 01:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 01:30:00', end: '2023-11-26 02:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 02:00:00', end: '2023-11-26 02:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 02:30:00', end: '2023-11-26 03:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 03:00:00', end: '2023-11-26 03:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 03:30:00', end: '2023-11-26 04:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 04:00:00', end: '2023-11-26 04:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-26 04:30:00', end: '2023-11-26 05:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      // 2023-11-27
      { name: 'Exitoso', start: '2023-11-27 00:00:00', end: '2023-11-27 00:30:00', color: '#88B437', textColor: 'black', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-27 00:30:00', end: '2023-11-27 01:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Con incidencias', start: '2023-11-27 01:00:00', end: '2023-11-27 01:30:00', color: '#BE2D2F', timed: true, icon: 'mdi-alert-circle' },
      { name: 'Exitoso', start: '2023-11-27 01:30:00', end: '2023-11-27 02:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-27 02:00:00', end: '2023-11-27 02:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-27 02:30:00', end: '2023-11-27 03:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      // 2023-11-28
      { name: 'Exitoso', start: '2023-11-28 00:00:00', end: '2023-11-28 00:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 00:30:00', end: '2023-11-28 01:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 01:00:00', end: '2023-11-28 01:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 01:30:00', end: '2023-11-28 02:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 02:00:00', end: '2023-11-28 02:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 02:30:00', end: '2023-11-28 03:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 03:00:00', end: '2023-11-28 03:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 03:30:00', end: '2023-11-28 04:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 04:00:00', end: '2023-11-28 04:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-28 04:30:00', end: '2023-11-28 05:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      // 2023-11-29
      { name: 'Exitoso', start: '2023-11-29 00:00:00', end: '2023-11-29 00:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 00:30:00', end: '2023-11-29 01:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 01:00:00', end: '2023-11-29 01:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 01:30:00', end: '2023-11-29 02:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 02:00:00', end: '2023-11-29 02:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 02:30:00', end: '2023-11-29 03:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 03:00:00', end: '2023-11-29 03:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 03:30:00', end: '2023-11-29 04:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 04:00:00', end: '2023-11-29 04:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 04:30:00', end: '2023-11-29 05:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 05:00:00', end: '2023-11-29 05:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-29 05:30:00', end: '2023-11-29 06:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Con incidencias', start: '2023-11-29 06:00:00', end: '2023-11-29 06:30:00', color: '#BE2D2F', timed: true, icon: 'mdi-alert-circle' },
      { name: 'Exitoso', start: '2023-11-29 06:30:00', end: '2023-11-29 07:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Con incidencias', start: '2023-11-29 07:00:00', end: '2023-11-29 07:30:00', color: '#BE2D2F', timed: true, icon: 'mdi-alert-circle' },
      // 2023-11-30
      { name: 'Con incidencias', start: '2023-11-30 00:00:00', end: '2023-11-30 00:30:00', color: '#BE2D2F', timed: true, icon: 'mdi-alert-circle' },
      { name: 'Exitoso', start: '2023-11-30 00:30:00', end: '2023-11-30 01:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-30 01:00:00', end: '2023-11-30 01:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-30 01:30:00', end: '2023-11-30 02:00:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Exitoso', start: '2023-11-30 02:00:00', end: '2023-11-30 02:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'En validación', start: '2023-11-30 02:30:00', end: '2023-11-30 03:00:00', color: '#E8A803', timed: true, icon: 'mdi-clock-time-eight' },
      { name: 'Exitoso', start: '2023-11-30 03:00:00', end: '2023-11-30 03:30:00', color: '#88B437', timed: true, icon: 'mdi-check-circle' },
      { name: 'Procesando', start: '2023-11-30 03:30:00', end: '2023-11-30 04:00:00', color: '#0352CC', timed: true, icon: 'mdi-cog-transfer' }
    ],
    textColors: ['blue darken-4', 'amber darken-4', 'green darken-4', 'red darken-4'],
    colors: ['blue lighten-4', 'amber lighten-4', 'green lighten-4', 'red lighten-4'],
    names: ['Procesando', 'En validación', 'Exitoso', 'Con incidencias'],
    eventTypes: [
      { name: 'processing', label: 'Procesando', color: 'blue lighten-4', textColor: 'blue darken-4' },
      { name: 'validating', label: 'En validación', color: 'amber lighten-4', textColor: 'amber darken-4' },
      { name: 'succeeded', label: 'Exitoso', color: 'green lighten-4', textColor: 'green darken-4' },
      { name: 'issued', label: 'Con incidencias', color: 'red lighten-4', textColor: 'red darken-4' }
    ],
    organization: '20394077101',
    organizations: [
      { name: 'SHERFARMA S.A.C', tax_id: '20376365680' },
      { name: 'Homecenters Peruanos Oriente S.A.C', tax_id: '20394077101' },
      { name: 'Tiendas Peruanas S.A.', tax_id: '20493020618' },
      { name: 'Homecenters Peruanos S.A.', tax_id: '20536557858' },
      { name: 'Tiendas Peruanas del Oriente S.A.C.', tax_id: '' },
      { name: 'Supermercados Peruanos S.A', tax_id: '20600414276' },
      { name: 'MAKRO SUPERMAYORISTA S.A.', tax_id: '20100070970' },
      { name: 'Plaza Vea Oriente', tax_id: '20492092313' },
      { name: 'OPERADORA DE SERVICIOS LOGISTICOS S.A.C.', tax_id: '20601233488' },
      { name: 'COMPAÑÍA HARD DISCOUNT S.A.C.', tax_id: '20607281921' },
      { name: 'COMPAÑÍA FOOD RETAIL S.A.C', tax_id: '20608280333' },
      { name: 'JOKR PERU SAC', tax_id: '20608300393' },
      { name: 'ADMINISTRACIÓN FOOD REGIONAL S.A.C', tax_id: '20607607061' },
      { name: 'QUIMICA SUIZA S.A.C', tax_id: '20608297163' },
      { name: 'VANTTIVE S.A.C', tax_id: '20100085225' },
      { name: 'Real Plaza S.R.L.', tax_id: '20547141068' },
      { name: 'Mifarma S.A.C', tax_id: '20511315922' },
      { name: 'Jorsa de la Selva S.A.C', tax_id: '20512002090' },
      { name: 'BOTICAS IP S.A.C.', tax_id: '20531502508' },
      { name: 'Inretail Pharma S.A.', tax_id: '20608430301' },
      { name: 'Farmacias Peruanas S.A.C.', tax_id: '20331066703' }
    ]
  }),
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      const eventType = this.eventTypes.find(e => e.label === event.name)
      return eventType.color
    },
    getEventTextColor (event) {
      const eventType = this.eventTypes.find(e => e.label === event.name)
      return eventType.textColor
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  }
}
</script>
<style>
.v-calendar-daily__intervals-head,
.v-calendar-daily__intervals-body {
  display: none;
}

.v-calendar-daily .v-calendar-daily__day-interval {
  border-top: none !important;
  border-bottom: none !important;
}

.v-calendar-events .v-event-timed {
  border-radius: 4px;
}
</style>
